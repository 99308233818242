import React, {useState, useEffect, useRef, useReducer} from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Layout from "../layouts/Layout";
import Header from "../components/Header";
import CountrySearch from "../components/Travel/CountrySearch";
import {countriesList} from "../components/Travel/Countries";
import {getCovidData} from "../components/Data";

import { useSpring, animated as a } from "react-spring";
import Results from "../components/Results";
import Autocomplete from "../components/Travel/Autocomplete";


const CountryDetail = ({data, location, ...props}) => {
    const [searchExpanded, setSearchExpanded] = useState(false)
    const flags = data.allFile.edges.map(f => f.node)
    const country = data.internalCountries
    const travelAdvisory = data.allInternalCountries.edges.map(c => c.node)

    let countries = []
    countriesList.map((item, i)=>{
          flags.map(f => {
              if (f.name === item.alpha2) {
                  countries.push(Object.assign({},item,{flag: f.publicURL}))
              }
          })
      })

    const countriesData = countries

    // const {countriesData} = props;
    const [items, setItems] = useState(countriesData);
    const [isLoading, setIsLoading] = useState(false);
    const [pageHeight, setPageHeight] = useState();
    const [isError, setIsError] = useState(false);
    const [isRandom, setIsRandom] = useState(false);
    const [state, setState] = useState({
        activeItem: 0,
        filteredItems: [],
        displayItems: false,
        inputValue: ''
    })
    const fieldRef = useRef();
    const heightRef = useRef(0);
    const itemsRef = useRef(0)
    const [itemsArray, setItemsArray] = useState([]);

    const [selected, dispatchSelected] = useReducer((state, action) => {
        switch (action.type) {
            case "add":
                return [{ id: state.length, name: action.name, data: action.data, flag: action.flag }, ...state];
            case "update":
                const updated = state.filter((_, index) => [{ data: action.data }])
                return updated;
            case "remove":
                const removedItem = state.filter((_, index) => index === action.index)
                const remove = state.filter(s => s.name !== removedItem[0].name);
                let newCountryItemsList = new Set([...items, removedItem[0]])
                newCountryItemsList = Array.from(newCountryItemsList).sort((a, b) => a.name > b.name ? 1 : -1)
                setItems(newCountryItemsList)
                return remove;
            case "empty":
                return []
            default:
                return state;
        }
    }, []);

    useEffect(
        () => {
            heightRef.current = window.innerHeight;
            window.addEventListener("resize", function () {
                setPageHeight(window.innerHeight);
            });
            handleAddItems(country)
        }, []
    )

    useEffect(
        () => {
            setItemsArray(selected.map(item => {
                const selectedCountry = countriesData.filter(function(country){
                    const c = country.name === item.name;
                    return c;
                });
                const countryArray = selectedCountry.map(c => {
                    c.data = item.data
                    return c
                })
                return selectedCountry;
            }))
        }, [selected]
    )

    useEffect(
        () => {
            itemsArray && itemsArray.length > 0 ? setSearchExpanded(false) : setSearchExpanded(true)
        }, [itemsArray]
    )

    // useEffect(
    //     () => {
    //         const container = document.querySelector('#countryResults');
    //         container.scrollTo({
    //           top: 0,
    //           left: 0,
    //           behavior: 'smooth'
    //         });
    //     }, [isLoading]
    // )


    function handleAddItems(e) {
        const newCountry = countriesData.filter(item => item.name.toLowerCase().includes(e.name.toLowerCase()))
        setItems(items.filter(i => i.name.toLowerCase() !== e.name.toLowerCase()));
        setIsLoading(true);
        countryCovidData(newCountry);
    }

    const countryCovidData = country => {
        const advisory = travelAdvisory.filter(
            c => country[0].name.toLowerCase().includes(c.name.toLowerCase()))[0]

        getCovidData(country[0]).then(responses => {
            const countryNames = items.map(a => a.name.toLowerCase());
            let allowed_in = [], restricted_in = [], allowed_count = "";

            if(advisory.status) {
                let allowed = advisory.allowed_to_travel.map(a => items.filter(i => i.name.toLowerCase() === a.toLowerCase()))
                allowed_in = allowed.filter(a => a.length)
                allowed_count = allowed_in.length;
                allowed_in = allowed_in.sort(() => .5 - Math.random()).slice(0, 5)
                let restricted = advisory.restricted.map(a => a.toLowerCase())
            }

            const all = {
                current: responses.current,
                diff: responses.diff,
                timeline: responses.timeline,
                prediction: responses.prediction,
                advisory,
                allowed_in,
                allowed_count
            }

            dispatchSelected({
                type: "add",
                name: country[0].name,
                flag: country[0].flag,
                data: all
            });
            setIsLoading(false);
            setIsRandom(false)
        })
        .catch((error) => {
            setIsLoading(false);
            setIsError(true);
            setTimeout(
                function(){
                    setIsError(false);
                    !itemsArray.length && setSearchExpanded(true);
                }, 2000);
        });
    }

    return (
        <Layout location={location}>
            <Header id="page-header" title="Covid Travel" animTrigger={!searchExpanded} />
            <Autocomplete
                items={items}
                state={state}
                setState={setState}
                isError={isError}
                isLoading={isLoading}
                fieldRef={fieldRef}
                itemsArray={itemsArray}
                handleAddItems={handleAddItems}
                setSearchExpanded={setSearchExpanded}
                />
            <Results items={itemsArray} dispatchSelected={dispatchSelected} handleAddItems={handleAddItems} />
        </Layout>
    )
}
export default CountryDetail;
export const countryDetail = graphql`
    query countryDetail($slug: String) {
        internalCountries(slug: {eq: $slug}) {
            name
            slug
            status
            quarantine
            allowed_to_travel
            restricted
            summary
            detail
            alpha2
            alpha3
        }
        allInternalCountries(filter: {id: {ne: "dummy"}}) {
            edges {
                node {
                    name
                    slug
                    status
                    quarantine
                    allowed_to_travel
                    restricted
                    summary
                    detail
                    alpha2
                    alpha3
                }
            }
        }
        allFile(filter: {
            extension: {
                regex: "/(png)/"
            },
            relativeDirectory: {
                eq: "flags"
            }
        }) {
            edges {
                node {
                    name
                    publicURL
                }
            }
        }
    }`
